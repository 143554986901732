<template>
  <div class="">
    <div class="data-title" v-if="!show">
      <a-button type="primary" @click="addContact">添加紧急联络人</a-button>
    </div>
    <a-table ref="dataTable" :columns="columns" :row-key="(record) => record.key" :data-source="list">
      <template v-slot:action="text, record, index">
        <div class="row-btn" @mousedown.stop @mouseup.stop>
          <a class="txt-btn" @click.stop="editRow(record, index)">编辑</a>
          <a class="txt-btn danger" @click.stop="delRow(record, index)">删除</a>
        </div>
      </template>
    </a-table>
    <a-modal
      v-model="visible"
      :maskClosable="false"
      title="选择考题"
      ok-text="确认"
      cancel-text="取消"
      @cancel="close"
      @ok="confirm"
    >
      <a-form-model ref="ruleForm" :model="form" :rules="rules" layout="vertical" label-align="left">
        <a-form-model-item label="姓名" prop="name">
          <a-input v-model="form.name" placeholder="输入姓名" allowClear></a-input>
        </a-form-model-item>
        <a-form-model-item label="联系电话" prop="tel">
          <a-input v-model="form.tel" placeholder="输入联系电话" allowClear></a-input>
        </a-form-model-item>
        <a-form-model-item label="此人与您的关系" prop="relationship">
          <a-input v-model="form.relationship" placeholder="输入关系" allowClear></a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { clone, isEmpty, randomString } from "../../../../common/js/tool";

export default {
  name: "EmergencyContact",
  model: {
    event: "change",
  },
  props: {
    value: String,
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {},
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        tel: [
          { required: true, message: "请输入联系电话", trigger: "blur" },
          { pattern: "^1[3-9]\\d{9}$", message: "请输入正确的手机号码" },
        ],
        relationship: [{ required: true, message: "请输入关系", trigger: "blur" }],
      },
      list: [],
      visible: false,
      editIndex: null,
    };
  },
  computed: {
    columns() {
      let columns = [
        { title: "姓名", dataIndex: "name" },
        { title: "联系电话", dataIndex: "tel" },
        { title: "此人与您的关系", dataIndex: "relationship" },
        { title: "操作", key: "action", fixed: "right", scopedSlots: { customRender: "action" } },
      ];
      if (this.show) {
        columns.pop();
      }
      return columns;
    },
  },
  watch: {
    value() {
      this.setValue();
    },
  },
  created() {
    this.setValue();
  },
  methods: {
    setValue() {
      let value = this.value;
      if (!isEmpty(value)) {
        this.list = JSON.parse(value);
      } else {
        this.list = [];
      }
    },
    dispatchEvent() {
      let list = this.list;
      if (list?.length > 0) {
        this.$emit("change", JSON.stringify(list));
      } else {
        this.$emit("change", "");
      }
    },
    addContact() {
      this.visible = true;
    },
    editRow(record, index) {
      this.form = clone(record);
      this.editIndex = index;
      this.visible = true;
    },
    delRow(record, index) {
      this.list.splice(index, 1);
      this.dispatchEvent();
    },
    close() {
      this.editIndex = null;
      this.visible = false;
      this.form = {};
    },
    confirm() {
      if (this.form.key) {
        this.list[this.editIndex] = this.form;
      } else {
        this.form.key = randomString(16);
        this.list.push(this.form);
      }
      this.dispatchEvent();
      this.close();
    },
  },
};
</script>

<style lang="less" scoped>
.data-title {
  margin-bottom: 12px;
}
</style>
