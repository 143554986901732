<template>
  <div>
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :layout="layout"
      :label-align="labelAlign"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
      :scroll-to-first-error="true"
    >
      <a-row :gutter="gutter">
        <a-col :span="24">
          <div class="common-title">基本信息</div>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="个人证件照" prop="personal_img">
            <form-upload
              v-model="form.personal_img"
              uploadTip="支持上传一寸免冠彩照，文件格式为PNG、JPG、JEPG"
            ></form-upload>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="身份证正面照片（国徽面）" prop="id_card_front_img">
            <form-upload v-model="form.id_card_front_img" uploadTip="文件格式为PNG、JPG、JEPG"></form-upload>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="身份证反面照片" prop="id_card_back_img">
            <form-upload
              v-model="form.id_card_back_img"
              uploadTip="文件格式为PNG、JPG、JEPG"
              @change="handleChangeFile"
            ></form-upload>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="档案编号" prop="file_number">
            <a-input v-model="form.file_number" placeholder="输入档案编号" allowClear></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="志工姓名" prop="name">
            <a-input v-model="form.name" placeholder="输入志工姓名（最多30字）" :maxLength="30" allowClear></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="性别" prop="sex">
            <a-radio-group v-model="form.sex">
              <a-radio :value="item.value" v-for="item in sexOptions" :key="item.value">
                {{ item.label }}
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="民族" prop="nation">
            <a-select
              placeholder="请选择民族"
              option-filter-prop="children"
              :options="nationOptions"
              v-model="form.nation"
              allowClear
              show-search
            >
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="身份证号" prop="id_card">
            <a-input v-model="form.id_card" placeholder="输入身份证号" allowClear @change="idCardChange"></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="年龄" prop="age">
            <a-input-number v-model="form.age" placeholder="请输入数字" :min="1" :step="1" allowClear></a-input-number>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="生日" prop="birthday">
            <a-input-group compact>
              <a-select
                placeholder="类型"
                :options="calendarOptions"
                v-model="form.calendar"
                style="width: 80px;"
              ></a-select>
              <a-date-picker
                v-show="form.calendar == 1"
                v-model="form.birthday"
                format="YYYY-MM-DD"
                value-format="YYYY-MM-DD"
                placeholder="选择日期"
                :disabled-date="disabledDate"
                allow-clear
              />
              <lunar-picker
                v-show="form.calendar == 2"
                v-model="form.birthday"
                format="YYYY-MM-DD"
                value-format="YYYY-MM-DD"
                placeholder="选择日期"
                :disabled-date="disabledDate"
                allow-clear
              />
            </a-input-group>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="手机号码" prop="tel">
            <a-input v-model="form.tel" placeholder="输入手机号码" allowClear></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="注册时间" prop="register_time">
            <a-date-picker
              v-model="form.register_time"
              :format="format"
              :value-format="format"
              placeholder="选择注册时间"
              allowClear
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item :label="form.register_status == 8 ? '原注册小组' : '注册小组'" prop="volunteer_group_id">
            <a-select
              placeholder="请选择注册小组（支持搜索）"
              option-filter-prop="children"
              :options="groupOptions"
              v-model="form.volunteer_group_id"
              allowClear
              show-search
              @change="groupChange"
            >
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="注册小组志工状态" prop="register_status">
            <a-select
              placeholder="请选择注册小组志工状态"
              :options="registerGroupStatusOptions"
              v-model="form.register_status"
              allowClear
            >
            </a-select>
          </a-form-model-item>
        </a-col>
        <ProfessionalTeam v-model="form.professionalGroup"></ProfessionalTeam>
        <!-- <a-col :span="12">
          <a-form-model-item label="注册小组职务">
            <a-select
              placeholder="请选择注册小组职务"
              :options="registerCatOption"
              v-model="form.register_post"
              allowClear
            >
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="专业小组职务">
            <a-select
              placeholder="请选择专业小组职务"
              :options="specialCatOptions"
              v-model="form.speciality_post"
              allowClear
            >
            </a-select>
          </a-form-model-item>
        </a-col> -->
        <a-col :span="12">
          <a-form-model-item label="身份证地址" prop="id_card_address">
            <a-input v-model="form.id_card_address" placeholder="输入身份证地址" allowClear></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="通讯地址" required validateStatus="success" class="mb-0">
            <a-row :gutter="gutter / 3">
              <a-col :span="9">
                <a-form-model-item label="" prop="communicate_province" class="special-item">
                  <a-cascader
                    :options="city"
                    placeholder="请选择所在地区"
                    v-model="form.communicate_province"
                    allowClear
                  />
                </a-form-model-item>
              </a-col>
              <a-col :span="15">
                <a-form-model-item label="" prop="communicate_address" class="special-item">
                  <a-input v-model="form.communicate_address" placeholder="输入详细地址" allowClear></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="是否皈依" class="mb-0">
            <a-form-model-item label="" class="input-item">
              <a-radio-group v-model="form.is_convert">
                <a-radio :value="item.value" v-for="item in conversionOptions" :key="item.value">
                  {{ item.label }}
                </a-radio>
              </a-radio-group>
            </a-form-model-item>
            <a-form-model-item label="" class="input-item" v-if="form.is_convert == 2">
              <a-input
                v-model="form.convert_temple"
                placeholder="请输入皈依寺院（最多30字）"
                :maxLength="30"
                allowClear
              ></a-input>
            </a-form-model-item>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="受戒情况">
            <a-select
              placeholder="请选择受戒情况"
              :options="disciplinedOptions"
              v-model="form.disciplined_status"
              allowClear
            >
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="报名渠道" class="mb-0">
            <a-row :gutter="gutter / 3">
              <a-col :span="9">
                <a-form-model-item label="" class="special-item">
                  <a-select placeholder="请选择" :options="channelOptions" v-model="form.channel_status" allowClear>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="15" v-if="form.channel_status != 3">
                <a-form-model-item label="" class="special-item">
                  <a-input
                    v-model="form.channel"
                    placeholder="输入报名渠道（最多30字）"
                    :maxLength="30"
                    allowClear
                  ></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="自有车辆情况" required validateStatus="success" class="mb-0">
            <a-form-model-item label="" prop="vehicle" class="input-item">
              <a-radio-group v-model="form.vehicle">
                <a-radio :value="item.value" v-for="item in carOptions" :key="item.value">
                  {{ item.label }}
                </a-radio>
              </a-radio-group>
            </a-form-model-item>
            <a-form-model-item
              label=""
              prop="vehicle_number"
              class="input-item"
              v-if="form.vehicle && form.vehicle != 1"
            >
              <a-input v-model="form.vehicle_number" placeholder="请输入车牌号" allowClear></a-input>
            </a-form-model-item>
          </a-form-model-item>
        </a-col>
        <a-col :span="24">
          <a-form-model-item label="志工特长">
            <a-checkbox-group v-model="form.speciality" name="checkboxgroup">
              <a-row :gutter="16">
                <a-col :span="3" v-for="item in specialityOptions" :key="item.value" class="speciality-item">
                  <a-checkbox :value="item.value" style="white-space: no-wrap">{{ item.label }}</a-checkbox>
                  <a-input
                    @click.prevent.stop
                    v-model="form.other_speciality"
                    placeholder="请输入其他特长"
                    allowClear
                    v-if="item.value == '其他' && form.speciality && form.speciality.indexOf('其他') > -1"
                  ></a-input>
                </a-col>
              </a-row>
            </a-checkbox-group>
          </a-form-model-item>
        </a-col>
        <a-col :span="24">
          <div class="common-title">毕业院校及家庭情况</div>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="最高学历">
            <a-select placeholder="请选择最高学历" :options="educationOptions" v-model="form.education" allowClear>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="毕业院校">
            <a-input
              v-model="form.graduate_school"
              placeholder="输入毕业院校（最多30字）"
              :maxLength="30"
              allowClear
            ></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="专业">
            <a-input
              v-model="form.major_studied"
              placeholder="输入所学专业（最多30字）"
              :maxLength="30"
              allowClear
            ></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="职业资质证书">
            <a-input
              v-model="form.certificate"
              placeholder="输入职业资质证书（最多50字）"
              :maxLength="50"
              allowClear
            ></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :span="24">
          <a-form-model-item label="工作单位" prop="work_unit">
            <a-textarea
              v-model="form.work_unit"
              placeholder="最多200字，格式如下：1、工作单位，岗位或职务，已退休（未退休空白）；2、自由职业或个体（职业类型，如：服装、开店等）3、无业在家、在校学生等。"
              :maxLength="200"
              allowClear
            ></a-textarea>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="婚姻情况">
            <a-select placeholder="请选择婚姻情况" :options="marriageOptions" v-model="form.marriage" allowClear>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="身体情况" required validateStatus="success" class="mb-0">
            <a-row :gutter="gutter / 3">
              <a-col :span="9">
                <a-form-model-item label="" prop="health" class="special-item">
                  <a-select placeholder="请选择身体情况" :options="bodyOptions" v-model="form.health" allowClear>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="15" v-if="form.health == 2">
                <a-form-model-item label="" class="special-item">
                  <a-input v-model="form.notice" placeholder="需要安排岗位时注意：" allowClear></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model-item>
        </a-col>
        <a-col :span="24">
          <a-form-model-item label="紧急联络人（直系亲属为主）">
            <EmergencyContact v-model="form.contact_person"></EmergencyContact>
          </a-form-model-item>
        </a-col>
        <a-col :span="24">
          <div class="common-title">人事记录</div>
        </a-col>
        <a-col :span="24">
          <a-form-model-item label="人事记录" :label-col="halfLabelCol" :wrapper-col="halfWrapperCol">
            <rich-editor v-model="form.personnel_records" placeholder="请输入"></rich-editor>
          </a-form-model-item>
        </a-col>
        <a-col :span="24">
          <a-form-model-item label="志工信息变更记录" :label-col="halfLabelCol" :wrapper-col="halfWrapperCol">
            <rich-editor v-model="form.change_record" placeholder="请输入"></rich-editor>
          </a-form-model-item>
        </a-col>
        <a-col :span="24">
          <a-form-model-item label="培训、修学记录" :label-col="halfLabelCol" :wrapper-col="halfWrapperCol">
            <rich-editor v-model="form.train_record" placeholder="请输入"></rich-editor>
          </a-form-model-item>
        </a-col>
        <a-col :span="24">
          <a-form-model-item label="奖励情况" :label-col="halfLabelCol" :wrapper-col="halfWrapperCol">
            <rich-editor v-model="form.reward" placeholder="请输入"></rich-editor>
          </a-form-model-item>
        </a-col>
        <a-col :span="24">
          <a-form-model-item label="违纪及重点关注情况" :label-col="halfLabelCol" :wrapper-col="halfWrapperCol">
            <rich-editor v-model="form.violation" placeholder="请输入"></rich-editor>
          </a-form-model-item>
        </a-col>
        <a-col :span="24">
          <a-form-model-item label="志工团小组担任职务" :label-col="halfLabelCol" :wrapper-col="halfWrapperCol">
            <rich-editor v-model="form.duties" placeholder="请输入"></rich-editor>
          </a-form-model-item>
        </a-col>
        <a-col :span="24">
          <a-form-model-item label="2021年起志工考勤记录" :label-col="halfLabelCol" :wrapper-col="halfWrapperCol">
            <rich-editor v-model="form.attendance_record" placeholder="请输入"></rich-editor>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
  </div>
</template>

<script>
import {
  clone,
  dataConvertOptions,
  isEmpty,
  validateIDCard,
  calculateAge,
  getFormattedBirthDateFromIdCard,
  timestampConvertString,
  stringConvertTimestamp,
} from "../../../../common/js/tool";
import {
  sexOptions,
  nationOptions,
  registerCatOption,
  conversionOptions,
  disciplinedOptions,
  channelOptions,
  carOptions,
  specialityOptions,
  educationOptions,
  marriageOptions,
  bodyOptions,
  volunStatusOptions,
  specialCatOptions,
  calendarOptions,
} from "../../../../common/hr/volunteer";
import city from "../../../../common/constant/city";
import EmergencyContact from "./EmergencyContact.vue";
import ProfessionalTeam from "./ProfessionalTeam.vue";
import moment from "moment";

const multipleKeys = ["communicate_province", "speciality"];
const ocrKeys = [
  {
    key: "name",
    keyword: "姓名",
  },
  {
    key: "sex",
    keyword: "性别",
  },
  {
    key: "nation",
    keyword: "民族",
  },
  {
    key: "birthday",
    keyword: "出生",
  },
  {
    key: "id_card_address",
    keyword: "住址",
  },
  {
    key: "id_card",
    keyword: "公民身份号码",
  },
];

export default {
  name: "MemberForm",
  components: {
    EmergencyContact,
    ProfessionalTeam,
  },
  props: {
    initial: Object,
    halfLabelCol: Object,
    halfWrapperCol: Object,
    labelCol: Object,
    wrapperCol: Object,
    labelAlign: {
      type: String,
      default: "left",
    },
    layout: {
      type: String,
      default: "vertical",
    },
    gutter: {
      type: [Number, String, Array],
      default: 32,
    },
  },
  data() {
    return {
      format: "YYYY-MM-DD",
      form: {},
      rules: {
        personal_img: [{ required: true, message: "请上传个人证件照", trigger: "change" }],
        id_card_front_img: [{ required: true, message: "请上传身份证正面照片", trigger: "change" }],
        id_card_back_img: [{ required: true, message: "请上传身份证反面照片", trigger: "change" }],
        file_number: [{ required: true, message: "请输入档案编号", trigger: "blur" }],
        name: [{ required: true, message: "请输入志工姓名", trigger: "blur" }],
        sex: [{ required: true, message: "请选择性别", trigger: "change" }],
        nation: [{ required: true, message: "请选择民族", trigger: "change" }],
        id_card: [{ required: true, message: "请输入身份证号", trigger: "blur" }],
        age: [{ required: true, message: "请输入年龄", trigger: "blur" }],
        calendar: [{ required: true, message: "请选择日历类型", trigger: "change" }],
        birthday: [{ required: true, message: "请选择生日日期", trigger: "change" }],
        tel: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          { pattern: "^1[3-9]\\d{9}$", message: "请输入正确的手机号码" },
        ],
        register_time: [{ required: true, message: "请选择注册时间", trigger: "change" }],
        volunteer_group_id: [{ required: true, message: "请选择注册小组", trigger: "change" }],
        register_status: [{ required: true, message: "请选择注册小组志工状态", trigger: "change" }],
        id_card_address: [{ required: true, message: "请输入身份证地址", trigger: "blur" }],
        communicate_province: [{ required: true, message: "请选择所在地区", trigger: "change" }],
        communicate_address: [{ required: true, message: "请输入详细地址", trigger: "blur" }],
        work_unit: [{ required: true, message: "请输入工作单位", trigger: "blur" }],
        health: [{ required: true, message: "请选择身体情况", trigger: "change" }],
        notice: [{ required: true, message: "请输入注意事项", trigger: "blur" }],
      },
      groupOptions: [],
      city,
      sexOptions,
      nationOptions,
      registerCatOption,
      conversionOptions,
      disciplinedOptions,
      channelOptions,
      carOptions,
      specialityOptions,
      educationOptions,
      marriageOptions,
      bodyOptions,
      volunStatusOptions,
      specialCatOptions,
      calendarOptions,
      registerGroupStatusOptions: [],
      groupPromise: null,
    };
  },
  computed: {
    ruleForm() {
      return this.$refs.ruleForm;
    },
  },
  watch: {
    initial: {
      handler() {
        this.init();
      },
      immediate: true,
    },
    "form.volunteer_group_id"(val) {
      if (val) {
        this.groupPromise.then(() => {
          let options = clone(volunStatusOptions);
          let target = this.groupOptions.find((item) => item.value == val);
          if (target.classify == 2) {
            // 专业组
            options.shift();
          }
          this.registerGroupStatusOptions = options;
        });
      } else {
        this.registerGroupStatusOptions = [];
      }
    },
  },
  created() {
    this.groupPromise = this.getGroup();
    // this.init();
  },
  methods: {
    // 获取小组列表
    getGroup() {
      return this.$axios({
        url: "/admin/volunteer-group",
        method: "GET",
        params: {
          sort: "sort,-id",
          pageSize: 9999,
        },
      }).then((res) => {
        this.groupOptions = dataConvertOptions(res.data);
      });
    },
    init() {
      let initial = this.initial;
      if (initial) {
        let form = clone(initial);
        multipleKeys.forEach((key) => {
          if (!isEmpty(form[key])) {
            form[key] = form[key].split(",");
          }
        });
        if (form.register_time) {
          form.register_time = timestampConvertString(form.register_time, "yyyy-MM-dd");
        }
        if (form.birthday) {
          form.birthday = timestampConvertString(form.birthday, "yyyy-MM-dd");
        }
        this.form = form;
      }
    },
    validate() {
      return new Promise((resolve, reject) => {
        this.ruleForm.validate((flag) => {
          if (flag) {
            let form = clone(this.form);
            multipleKeys.forEach((key) => {
              if (!isEmpty(form[key])) {
                form[key] = form[key].join(",");
              }
            });

            if (form.register_time) {
              form.register_time = stringConvertTimestamp(form.register_time);
            }
            if (form.birthday) {
              form.birth_date = form.birthday.slice(5);
              form.birthday = stringConvertTimestamp(form.birthday);
            }

            let oldGroup = clone(this.initial?.professionalGroup || []);
            let volunteer_professional_group = {
              add: [],
              update: [],
              delete: [],
            };
            form.professionalGroup = form.professionalGroup?.filter(
              (item) => !isEmpty(item.id) || !isEmpty(item.volunteer_group)
            ); // 筛选出 存在id 和 小组名称存在的
            form.professionalGroup?.forEach((item) => {
              if (item.id) {
                volunteer_professional_group.update.push(item);
                // 去除当前仍存在的，剩下的为删除的
                let index = oldGroup.findIndex((old) => old.id == item.id);
                oldGroup.splice(index, 1);
              } else if (item.key) {
                volunteer_professional_group.add.push(item);
              }
            });
            volunteer_professional_group.delete = oldGroup.filter((item) => item.id);
            form.volunteer_professional_group = JSON.stringify(volunteer_professional_group);
            // console.log("form:", form);
            resolve(form);
          } else {
            this.scrollToError();
            reject();
          }
        });
      });
    },
    scrollToError() {
      this.$nextTick(() => {
        const errorDiv = this.ruleForm.$el.querySelector(".ant-form-explain");
        if (errorDiv) {
          errorDiv.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      });
    },
    groupChange() {
      this.$set(this.form, "register_status", undefined);
    },
    // ocr识别匹配
    ocrMatch(TextDetections) {
      let form = {};
      let preIndex; // 记录上一个匹配下标
      let preWIndex; // 记录上一个字符串匹配下标
      ocrKeys.forEach((item, kIndex) => {
        let { key, keyword } = item;
        let wIndex;
        for (let i = 0; i < TextDetections.length; i++) {
          const Detection = TextDetections[i];
          let DetectedText = Detection.DetectedText.trim();
          if (DetectedText) {
            wIndex = DetectedText.indexOf(keyword);
          }
          if (wIndex > -1) {
            if (preIndex > -1) {
              let preKey = ocrKeys[kIndex - 1].key;
              // 当存在上一个
              if (i == preIndex) {
                // 当前匹配与上一个重叠
                let preKeyword = ocrKeys[kIndex - 1].keyword;
                form[preKey] = form[preKey].slice(0, wIndex - preWIndex - preKeyword.length);
              } else if (i - preIndex > 1) {
                // 两者之间存在其他信息,则信息都存入上一个字段中
                TextDetections.slice(preIndex + 1, i).forEach((Detection) => {
                  let DetectedText = Detection.DetectedText.trim();
                  if (DetectedText) {
                    form[preKey] += DetectedText;
                  }
                });
              }
            }
            form[key] = DetectedText.slice(wIndex + keyword.length);
            preIndex = i;
            preWIndex = wIndex;
            break;
          }
        }
      });
      if (preIndex < TextDetections.length - 1) {
        // 剩下的都给最后一个字段
        let preKey = ocrKeys[ocrKeys.length - 1].key;
        TextDetections.slice(preIndex + 1).forEach((Detection) => {
          let DetectedText = Detection.DetectedText.trim();
          if (DetectedText) {
            form[preKey] += DetectedText;
          }
        });
      }
      console.log("form:", form);
      return form;
    },
    handleChangeFile(url) {
      if (!url) {
        return;
      }
      this.$axios({
        url: "/admin/volunteer/ocr",
        method: "POST",
        data: {
          img_url: url,
        },
      }).then((res) => {
        let TextDetections = res.TextDetections;
        let form = this.ocrMatch(TextDetections);
        if (form.birthday) {
          // 提取年、月、日
          const match = form.birthday.match(/(\d{4})年(\d{1,2})月(\d{1,2})日/);
          const year = parseInt(match[1], 10);
          const month = parseInt(match[2], 10) - 1; // 月份在JavaScript中是从0开始的
          const day = parseInt(match[3], 10);
          // 创建Date对象
          const date = new Date(year, month, day).pattern("yyyy-MM-dd");
          form.birthday = date;
        }
        if (form.sex) {
          let target = sexOptions.find((item) => item.label == form.sex);
          form.sex = target?.value;
        }
        let id_card = form.id_card;
        if (!isEmpty(id_card) && validateIDCard(id_card)) {
          form.age = calculateAge(id_card);
        }
        for (const key in form) {
          let value = form[key];
          this.$set(this.form, key, value);
        }
      });
    },
    idCardChange(e) {
      let val = e.target.value;
      if (!isEmpty(val) && validateIDCard(val)) {
        let age = calculateAge(val);
        this.$set(this.form, "age", age);
        let birthday = getFormattedBirthDateFromIdCard(val);
        this.$set(this.form, "birthday", birthday);
      }
    },
    disabledDate: (current) => {
      return current && current >= moment().endOf("day");
    },
  },
};
</script>

<style lang="less" scoped>
.ant-radio-group {
  min-height: 32px;
  line-height: 32px;
}
.input-item {
  display: inline-block;
  padding-bottom: 0;
}
.special-item {
  display: inline-block;
  width: 100%;
  padding: 0;
}
.mb-0 {
  margin-bottom: 0;
}
.ant-input-number {
  width: 240px;
}
.common-title {
  font-size: 20px;
  margin-bottom: 12px;
  font-weight: 600;
}
.title-tip {
  font-size: 12px;
}

.number-input {
  width: 120px;
  margin: 0 12px;
}
.item-select {
  width: 240px;
  margin: 0 12px;
}

.speciality-item {
  padding: 6px;
}
</style>
