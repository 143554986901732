<template>
  <a-col :span="24">
    <a-form-model-item class="mb-0">
      <template v-slot:label>
        <a-space size="large">
          <span>专业小组情况</span>
          <a class="txt-btn" @click="addGroup">添加专业小组</a>
        </a-space>
      </template>
    </a-form-model-item>
    <template v-for="(group, index) in list">
      <a-col :span="12" :key="`group-${group.id || group.key}`">
        <a-form-model-item>
          <template v-slot:label>
            <a-space size="large">
              <span>专业小组{{ index + 1 }}</span>
              <a class="txt-btn danger" @click.stop="delGroup(group, index)">删除</a>
            </a-space>
          </template>
          <a-select
            placeholder="请选择专业小组（支持搜索）"
            option-filter-prop="children"
            :options="groupOptions"
            v-model="group.volunteer_group"
            allowClear
            show-search
            @change="dispatchEvent"
          >
          </a-select>
        </a-form-model-item>
      </a-col>
      <a-col :span="12" :key="`status-${group.id || group.key}`">
        <a-form-model-item label="专业小组志工状态">
          <a-select
            placeholder="请选择专业小组志工状态"
            :options="specialityGroupStatusOptions"
            v-model="group.volunteer_status"
            allowClear
            @change="dispatchEvent"
          >
          </a-select>
        </a-form-model-item>
      </a-col>
    </template>
  </a-col>
</template>

<script>
import { volunStatusOptions } from "../../../../common/hr/volunteer";
import { dataConvertOptions, clone, randomString } from "../../../../common/js/tool";

export default {
  name: "EmergencyContact",
  model: {
    event: "change",
  },
  props: {
    value: Array,
  },
  data() {
    return {
      list: [],
      groupOptions: [],
      specialityGroupStatusOptions: [],
    };
  },
  watch: {
    value() {
      this.setValue();
    },
  },
  created() {
    this.specialityGroupStatusOptions = clone(volunStatusOptions);
    this.specialityGroupStatusOptions.shift();
    this.getGroup();
    this.setValue();
  },
  methods: {
    // 获取小组列表
    getGroup() {
      return this.$axios({
        url: "/admin/volunteer-group",
        method: "GET",
        params: {
          sort: "sort,-id",
          pageSize: 9999,
          "filter[classify]": 2,
        },
      }).then((res) => {
        this.groupOptions = dataConvertOptions(res.data);
      });
    },
    setValue() {
      let value = this.value;
      if (value) {
        this.list = clone(value);
      } else {
        this.list = [];
      }
    },
    dispatchEvent() {
      let list = this.list;
      if (list?.length > 0) {
        this.$emit("change", list);
      } else {
        this.$emit("change", null);
      }
    },
    addGroup() {
      this.list.push({ key: randomString(16) });
      this.dispatchEvent();
    },
    delGroup(group, index) {
      this.list.splice(index, 1);
      this.dispatchEvent();
    },
  },
};
</script>

<style lang="less" scoped>
.data-title {
  margin-bottom: 12px;
}
.mb-0 {
  margin-bottom: 0;
}
</style>
